/* body {
    background-color: #FFCC00;
    padding: 20px;
    margin: 0;
  }
  h1, h2, p, ul, li {
    font-family: sans-serif;
  }
  ul.header li {
    display: inline;
    list-style-type: none;
    margin: 0;
  }
  ul.header {
    background-color: #111;
    padding: 0;
  }
  ul.header li a {
    color: #FFF;
    font-weight: bold;
    text-decoration: none;
    padding: 20px;
    display: inline-block;
  }
  .content {
    background-color: #FFF;
    padding: 20px;
  }
  .content h2 {
    padding: 0;
    margin: 0;
  }
  .content li {
    margin-bottom: 10px;
  }

  .active {
    background-color: #0099FF;
  } */

  body {
    background-color: #F5F5F5;
}
/* 
 copied from cb-form-builder 
* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  margin: 0;
  top: -16px;
  position: relative;
  box-sizing: border-box;
  font-family: 'Helvetica', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.onHover {
  cursor: pointer;
}

.preview_item {
  cursor: move;
}

.bg-default {
  background-color: #fafafa !important;
}

.demo-form {
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  height: 100vh;
  overflow: auto;
  position: fixed;
  background-color: rgba(250, 250, 250, 0.8);
}

.form_editor {
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(250, 250, 250, 0.8);
}

.final-preview {
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  position: fixed;
  background-color: rgba(250, 250, 250, 0.8);
}

.demo-editor {
  height: 200px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
}

.demo-wrapper {
  margin: 0;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.toolbar-item {
  transition: all 0.1s ease-in;
}

.toolbar-item:hover {
  box-shadow: 0px 5px 10px gray;
}

.sigCanvas {
  border: 1px solid #aaa;
} */


/* hide some things on the form builder */
.fld-name {
  display: none !IMPORTANT;
}

.option-value {
  display: none !IMPORTANT;
}

.MuiTableCell-root {
     display: table-cell;
      padding: 0px !IMPORTANT;
}

svg > g > g:last-child { pointer-events: none }